import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Users.css'
import HOC from '../../Components/HOC/HOC'
import { Link, useLocation } from 'react-router-dom';

import { IoSearch } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineBlock } from "react-icons/md";
import { IoEyeOutline } from "react-icons/io5";
import { BaseUrl, getAuthHeaders } from '../../Components/BaseUrl/BaseUrl';


import CustomPagination from '../../Components/Pagination/Pagination';


import img2 from '../../Images/user.webp'


const Users = () => {
    const location = useLocation();
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(location.state?.page || 1);
    const limit = 10;
    const [search, setSearch] = useState("");
    const [totalPages, setTotalPages] = useState(0);


    const cachedAdminData = localStorage.getItem('adminData');
    const adminData = JSON.parse(cachedAdminData);
    const role = localStorage.getItem('role');

    let permissionsArray = [];

    if (adminData && adminData.permissions) {
        permissionsArray = adminData.permissions;
    } else {
        console.log('Permissions array not found in adminData.');
    }




    const fetchUserData = useCallback(() => {
        axios.get(`${BaseUrl}api/v1/admin/all/user?page=${page}&limit=${limit}&search=${search}`, getAuthHeaders())
            .then(response => {
                setUserData(response.data.data.docs);
                setTotalPages(response.data.data.totalPages);
            })
            .catch(error => {
                console.error('Error fetching rider data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [page, limit, search]);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);





    const handlePageChange = (newPage) => {
        if (newPage === page || newPage < 1 || newPage > totalPages) return;

        setPage(newPage); // This will trigger a re-fetch of data
    };


    const handleSearch = (event) => {
        setPage(1);
        setSearch(event.target.value);
    };

    const deleteUser = (userId) => {
        axios.delete(`${BaseUrl}api/v1/admin/delete/driver/${userId}`, getAuthHeaders())
            .then(response => {
                toast.success("User deleted successfully");
                fetchUserData();
            })
            .catch(error => {
                console.error('Error to deleting User:', error);
                toast.error("Failed to delete user. Please try again later.");
            });
    };

    const blockUser = (userId) => {
        axios.put(`${BaseUrl}api/v1/admin/block/driver/${userId}`, getAuthHeaders())
            .then(response => {
                toast.success("User is blocked successfully");
                fetchUserData(prevUserData => {
                    return prevUserData.map(user => {
                        if (user._id === userId) {
                            return { ...user, isBlock: true };
                        }
                        return user;
                    });
                });
            })
            .catch(error => {
                // console.error('Error blocking rider:', error);
                toast.error("Failed to block user. Please try again later.");
            });
    };
    const unblockUser = (userId) => {
        axios.put(`${BaseUrl}api/v1/admin/unblock/driver/${userId}`, getAuthHeaders())
            .then(response => {
                // console.log('Rider is unblocked successfully');
                toast.success("User is unblocked successfully'");
                fetchUserData(prevUserData => {
                    return prevUserData.map(user => {
                        if (user._id === userId) {
                            return { ...user, isBlock: false };
                        }
                        return user;
                    });
                });
            })
            .catch(error => {
                console.error('Error unblocking User:', error);
                toast.error("Failed to unblock user. Please try again later.");
            });
    };




    const handleExport = () => {

        const exportUrl = `${BaseUrl}api/v1/downloadUser`;

        axios.get(exportUrl, getAuthHeaders())
            .then(response => {
                let downloadUrl = response.data.data;
                if (downloadUrl.startsWith('./')) {
                    downloadUrl = `https://tripriderapp.in/api${downloadUrl.slice(1)}`;
                }

                window.open(downloadUrl, '_blank');
            })
            .catch(error => {
                console.error('Error exporting data:', error);
                toast.error('Failed to export data. Please try again later.');
            });
    };



    return (
        <>
            <div className='rider'>
                <div className='rider1'>
                    <div className='rider2'>
                        <div className='rider3'>
                            <h6>All Users</h6>
                        </div>

                        <div className='rider4'>
                            <button onClick={handleExport}>Export</button>
                            <div className='rider5'>
                                <div className='rider6'>
                                    <IoSearch />
                                </div>
                                <input type="search" name="" id="" placeholder='Search User'
                                    onChange={handleSearch} />
                            </div>
                        </div>
                    </div>
                    <div className='rider7'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Profile Image</th>
                                    <th>User Name</th>
                                    <th>Email</th>
                                    <th>Phone No.</th>
                                    <th>Category</th>
                                    <th>Wallet Balance</th>
                                    <th>Total Trips</th>
                                    <th>Action Buttons</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="8" style={{ color: "#b20202", fontWeight: "600", fontSize: "18px" }}>Loading users...</td>
                                    </tr>
                                ) : userData?.length === 0 ? (
                                    <tr>
                                        <td colSpan="8" style={{ color: "#b20202", fontWeight: "600", fontSize: "18px" }}>User not found</td>
                                    </tr>
                                ) : (
                                    userData?.map(user => (
                                        <tr key={user?._id}>
                                            <td>
                                                <img src={user?.profilePicture || img2} alt="No image" style={{ width: '60px', height: "60px", borderRadius: "100%" }} />
                                            </td>
                                            <td>
                                                {user?.name?.length > 15 ? `${user?.name?.substring(0, 15)}....` : user.name}
                                            </td>
                                            <td>{user?.email}</td>
                                            <td>{user?.mobileNumber}</td>
                                            <td>{user?.category?.category}</td>
                                            <td style={{ color: '#F52D56' }}>{user?.wallet}</td>
                                            <td>{user?.totalBooking}</td>
                                            <td>
                                                <div className='rider9'>
                                                    {role === 'superAdmin' ? (
                                                        <>
                                                            <div className='rider10' onClick={() => deleteUser(user._id)}>
                                                                <RiDeleteBinLine color='#667085' size={20} />
                                                                <p>Delete</p>
                                                            </div>
                                                            <div className='rider10' onClick={() => { user.isBlock ? unblockUser(user._id) : blockUser(user._id) }}>
                                                                <MdOutlineBlock color={user.isBlock ? "red" : "#667085"} size={20} />
                                                                <p style={{ color: user.isBlock ? 'red' : '#667085' }}>Block/Unblock</p>
                                                            </div>
                                                            <div className='rider10'>
                                                                <Link
                                                                    to={`/user_details/${user._id}`}
                                                                    className='sidebar-link'
                                                                    state={{ page }}
                                                                >
                                                                    <IoEyeOutline color='#667085' size={20} />
                                                                    <p>View</p>
                                                                </Link>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {permissionsArray.some(permission => permission.name === 'All Users' && permission.delete) && (
                                                                <div className='rider10' onClick={() => deleteUser(user._id)}>
                                                                    <RiDeleteBinLine color='#667085' size={20} />
                                                                    <p>Delete</p>
                                                                </div>
                                                            )}
                                                            {permissionsArray.some(permission => permission.name === 'All Users' && permission.block) && (
                                                                <div className='rider10' onClick={() => { user.isBlock ? unblockUser(user._id) : blockUser(user._id) }}>
                                                                    <MdOutlineBlock color={user.isBlock ? "red" : "#667085"} size={20} />
                                                                    <p style={{ color: user.isBlock ? 'red' : '#667085' }}>Block/Unblock</p>
                                                                </div>
                                                            )}
                                                            {permissionsArray.some(permission => permission.name === 'All Users' && permission.view) && (
                                                                <div className='rider10'>
                                                                    <Link
                                                                        to={`/user_details/${user._id}`}
                                                                        className='sidebar-link'
                                                                        state={{ page }}
                                                                    >
                                                                        <IoEyeOutline color='#667085' size={20} />
                                                                        <p>View</p>
                                                                    </Link>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))

                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='rider_details555'>
                    <CustomPagination
                        page={page}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </div>
        </>
    )
}

export default HOC(Users)