import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import HOC from '../../Components/HOC/HOC';
import MapTwogeo from '../../Components/Map/MapTwogeo';
import { BaseUrl, getAuthHeaders } from '../../Components/BaseUrl/BaseUrl';

import './Geofencing.css'

const Geofencing = () => {
    const [locationdata, setLoctionData] = useState([]);
    const [count, setTotalCount] = useState([]);
    const { lat, long } = useParams();

    useEffect(() => {
        let isMounted = true;

        const fetchLocationData = async () => {
            try {
                const response = await axios.get(
                    `${BaseUrl}api/v1/admin/allDriver/ForGeoFencing?lat=${lat}&long=${long}`,
                    getAuthHeaders()
                );

                if (isMounted) {
                    // Extract location data
                    const locations = response.data.data.map(item => ({
                        latitude: item.location.coordinates[0],
                        longitude: item.location.coordinates[1],
                        driverId: item._id,
                        driverName: item.name,
                    }));

                    // Set location data
                    setLoctionData(locations);

                    // Get total count of the response
                    const totalCount = response?.data?.data?.length;
                    console.log('Total Locations Count:', totalCount);

                    // If you want to set totalCount in state, you can use:
                    setTotalCount(totalCount); // Ensure `setTotalCount` is defined in your state
                }
            } catch (error) {
                console.error('Error fetching location data:', error);
            }
        };


        fetchLocationData();

        return () => {
            isMounted = false;
        };
    }, [lat, long]);


    const navigate = useNavigate()

    return (
        <div className='rider'>
            <div className='rider1'>
                <div className='rider2'>
                    <div className='rider3'>
                        <h6>Eagle’s Eye ({count})</h6>
                    </div>
                    <div className='rider4'>
                        <button onClick={() => navigate(-1)}>Back</button>
                    </div>
                </div>
                <div className='geo1'>
                    <MapTwogeo locations={locationdata} />
                </div>
            </div>
        </div>
    );
};

export default HOC(Geofencing);
