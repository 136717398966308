// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.geo1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}


.showingdetailsmap {
    padding: 1rem;
}

.showingdetailsmap h4 {
    font-size: 15px;
    margin: 0;
    margin-bottom: 1rem;
}


.showingdetailsmap button {
    width: 100px;
    height: 31px;
    border: none;
    outline: none;
    border-radius: 8px;
    /* background-color: #c3052c; */
    background-color: #b20202;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 14px;
    /* color: #ffffff; */
    color: #FFFFFF;
    line-height: 21px;
    margin: 0;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Geofencing view/Geofencing.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;;AAGA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,SAAS;IACT,mBAAmB;AACvB;;;AAGA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,+BAA+B;IAC/B,yBAAyB;IACzB,qCAAqC;IACrC,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,cAAc;IACd,iBAAiB;IACjB,SAAS;IACT,eAAe;AACnB","sourcesContent":[".geo1 {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 50px;\n}\n\n\n.showingdetailsmap {\n    padding: 1rem;\n}\n\n.showingdetailsmap h4 {\n    font-size: 15px;\n    margin: 0;\n    margin-bottom: 1rem;\n}\n\n\n.showingdetailsmap button {\n    width: 100px;\n    height: 31px;\n    border: none;\n    outline: none;\n    border-radius: 8px;\n    /* background-color: #c3052c; */\n    background-color: #b20202;\n    font-family: \"Montserrat\", sans-serif;\n    font-weight: 700;\n    font-size: 14px;\n    /* color: #ffffff; */\n    color: #FFFFFF;\n    line-height: 21px;\n    margin: 0;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
