import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import './SettleBooking.css'
import HOC from '../../Components/HOC/HOC'
import { BaseUrl, getAuthHeaders } from '../../Components/BaseUrl/BaseUrl';
import { useReactToPrint } from "react-to-print";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MapComponentone from '../../Components/Map/Mapone'

const SettleBookingDetails = () => {
    const location = useLocation();
    const { page = 1 } = location.state || {};
    const componentRef = React.useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const printForm = () => {
        var elements = document.getElementsByClassName("printData");


        for (let i = 0; i < elements.length; i++) {
            elements[i].style.padding = "10px 20px";
        }
        handlePrint();

        setTimeout(() => {
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.padding = "0px 0px";
            }
        }, 1000);

    }

    const navigate = useNavigate();
    const { id } = useParams();
    const [bookingdata, setBookingData] = useState(null);



    const fetchBookingData = async () => {
        try {
            const response = await axios.get(`${BaseUrl}api/v1/getSettleBookingById/${id}`, getAuthHeaders())
            setBookingData(response.data.data);
        } catch (error) {
            console.error('Error fetching Booking data:', error);
        }
    };

    useEffect(() => {
        fetchBookingData();
    }, [id]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const formattedDate = `${date.getDate().toString().padStart(2, '0')} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;

        return `${formattedDate} `;
    };

    const formatTime = (dateString) => {
        const date = new Date(dateString);


        let hours = date.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format
        const formattedTime = `${hours.toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}${ampm}`;

        // Combine date and time
        return `${formattedTime} `;
    };








    return (
        <>
            <div className='rider'>
                <div className='dashboardconatiner'>
                    {bookingdata && (
                        <>
                            <div className='settledrivercantainer'>
                                <div className='settledriver1'>
                                    <h6>Map View</h6>
                                    <button onClick={printForm}>Print</button>
                                    <button onClick={() => navigate('/settlebooking', { state: { page } })}>Back</button>
                                </div>
                                <div className='settledriver2'>
                                    <MapComponentone pickupLatitude={bookingdata.current.latitude} pickupLongitude={bookingdata.current.longitude} dropLatitude={bookingdata.drop.latitude} dropLongitude={bookingdata.drop.longitude} />
                                </div>
                                <div ref={componentRef} className='printData'>
                                    <div className='settledriver3'>
                                        <label htmlFor="">Trip Location</label>
                                        <div className='settledriver4'>
                                            <div className='settledriver5'>
                                                <label htmlFor="">Pickup Location</label>
                                                <div className='settledriver6'>
                                                    <p>{bookingdata.current.address}</p>
                                                </div>
                                            </div>
                                            <div className='settledriver5'>
                                                <label htmlFor="">Drop Location</label>
                                                <div className='settledriver6'>
                                                    <p>{bookingdata.drop.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='settledriver3'>
                                        <label htmlFor="">Request</label>

                                        <div className='settledriver44'>
                                            <div className='settledriver5'>
                                                <label htmlFor="">Type of Cab</label>
                                                <div className='settledriver66'>
                                                    <p>{bookingdata?.car?.name}</p>
                                                </div>
                                            </div>
                                            <div className='settledriver5'>
                                                <label htmlFor="">Create Booking</label>
                                                <div className='settledriver66'>
                                                    <p>{formatDate(bookingdata?.createdAt)}</p>
                                                </div>
                                            </div>
                                            <div className='settledriver5'>
                                                <label htmlFor="">Start Date Booking</label>
                                                <div className='settledriver66'>
                                                    <p>{formatDate(bookingdata.startDate)}</p>
                                                </div>
                                            </div>
                                            <div className='settledriver5'>
                                                <label htmlFor="">End Date Booking</label>
                                                <div className='settledriver66'>
                                                    <p>{formatDate(bookingdata.endDate)}</p>
                                                </div>
                                            </div>
                                            <div className='settledriver5'>
                                                <label htmlFor="">Total Days</label>
                                                <div className='settledriver66'>
                                                    <p>{bookingdata.day}</p>
                                                </div>
                                            </div>
                                            <div className='settledriver5'>
                                                <label htmlFor="">KM</label>
                                                <div className='settledriver66'>
                                                    <p>{bookingdata.km}</p>
                                                </div>
                                            </div>
                                            <div className='settledriver5'>
                                                <label htmlFor="">PickUp Time</label>
                                                <div className='settledriver66'>
                                                    <p>{bookingdata.pickUpTime}</p>
                                                </div>
                                            </div>
                                            <div className='settledriver5'>
                                                <label htmlFor="">Drop Time</label>
                                                <div className='settledriver66'>
                                                    <p>{bookingdata.dropTime}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='settledriver3'>
                                        <label htmlFor="">User Details</label>

                                        <div className='settledriver44'>
                                            <div className='settledriver5'>
                                                <label htmlFor="">Name</label>
                                                <div className='settledriver66'>
                                                    <p>{bookingdata.user?.name}</p>
                                                </div>
                                            </div>
                                            <div className='settledriver5'>
                                                <label htmlFor="">Email</label>
                                                <div className='settledriver66'>
                                                    <p>{bookingdata.user?.email}</p>
                                                </div>
                                            </div>
                                            <div className='settledriver5'>
                                                <label htmlFor="">Mobile Number</label>
                                                <div className='settledriver66'>
                                                    <p>{bookingdata.user?.mobileNumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='settledriver3'>
                                        <label htmlFor="">Driver Details</label>

                                        <div className='settledriver44'>
                                            <div className='settledriver5'>
                                                <label htmlFor="">Name</label>
                                                <div className='settledriver66'>
                                                    <p>{bookingdata.driver?.name}</p>
                                                </div>
                                            </div>
                                            <div className='settledriver5'>
                                                <label htmlFor="">Email</label>
                                                <div className='settledriver66'>
                                                    <p>{bookingdata.driver?.email}</p>
                                                </div>
                                            </div>
                                            <div className='settledriver5'>
                                                <label htmlFor="">Mobile Number</label>
                                                <div className='settledriver66'>
                                                    <p>{bookingdata.driver?.mobileNumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='settledriver3'>
                                        <label htmlFor="">Bill Details</label>
                                        <div className='settledriver7'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[
                                                        { title: 'Base Price', value: bookingdata?.price },
                                                        { title: 'Ride Fee', value: bookingdata?.rideFee },
                                                        { title: 'Service Tax', value: bookingdata?.serviceTax },
                                                        // { title: 'Night Charges', value: bookingdata?.nightCharges },
                                                        { title: 'Parking Rate', value: bookingdata?.ParkingRate },
                                                        { title: 'Km Rate', value: bookingdata?.kmRate },
                                                        { title: 'Time Rate', value: bookingdata?.timeRate },
                                                        { title: 'Platform Charges GST', value: bookingdata?.plateFormChargesGst },
                                                        { title: 'Platform Charges', value: bookingdata?.plateFormCharges },
                                                        { title: 'Surge Charges', value: bookingdata?.surgeCharges },
                                                        { title: 'Toll Charge', value: bookingdata?.tollCharge },
                                                        { title: 'Cancellation Charges', value: bookingdata?.cancellationCharges },
                                                        { title: 'Insurance Premium', value: bookingdata?.insurancePremium },
                                                        { title: 'Railway PickUp Charge', value: bookingdata?.railwayPickUpCharge },
                                                        { title: 'Waiting Charge', value: bookingdata?.waitingCharge },
                                                        { title: 'Other Charge', value: bookingdata?.otherCharge },
                                                        { title: 'Taxes', value: bookingdata?.Taxes },
                                                        { title: 'Net Price', value: bookingdata?.netPrice },
                                                        { title: 'Discount', value: bookingdata?.discount },
                                                        { title: 'GST', value: bookingdata?.gst },
                                                        { title: 'Admin Amount', value: bookingdata?.adminAmount },
                                                        { title: 'Driver Commission', value: bookingdata?.driverAmount },
                                                        { title: 'Total Amount', value: bookingdata?.totalPrice },
                                                    ]
                                                        // Filter rows with value > 0
                                                        .filter(item => item.value > 0)
                                                        .map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.title}</td>
                                                                <td>-</td>
                                                                <td>{item.value}</td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default HOC(SettleBookingDetails);
