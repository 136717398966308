import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import './SOSUpdate.css'
import HOC from '../../Components/HOC/HOC'
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { IoSearch } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import img2 from '../../Images/user.webp'
import { BaseUrl, getAuthHeaders } from '../../Components/BaseUrl/BaseUrl';
import CustomPagination from '../../Components/Pagination/Pagination';



const SOSUpdate = () => {
    const location = useLocation();
    const [sosdata, setSosData] = useState([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShow1, setModalShow1] = React.useState(false);
    const [sosId, setSOSId] = useState('')
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(location.state?.page || 1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [status, setStatus] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');


    const fetchSOSData = useCallback(() => {
        axios.get(`${BaseUrl}api/v1/getAllSosRequest?page=${page}&limit=${limit}&search=${search}&status=${status}&fromDate=${fromDate}&toDate=${toDate}`, getAuthHeaders())
            .then(response => {
                setSosData(response.data.data.docs);
                setTotalPages(response.data.data.pages);
            })
            .catch(error => {
                console.error('Error fetching SOS data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [page, limit, search, status, fromDate, toDate,]);

    useEffect(() => {
        fetchSOSData();
    }, [fetchSOSData]);



    const formatDate = (dateString) => {
        const date = new Date(dateString);

        // Add 5 hours and 30 minutes to convert UTC to IST
        date.setHours(date.getHours() + 5);
        date.setMinutes(date.getMinutes() + 30);

        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const formattedDate = `${date.getDate().toString().padStart(2, '0')} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
        return formattedDate;
    };

    const formatTime = (dateString) => {
        const date = new Date(dateString);

        // Add 5 hours and 30 minutes to convert UTC to IST
        date.setHours(date.getHours() + 5);
        date.setMinutes(date.getMinutes() + 30);

        // Extract hours, minutes, and AM/PM
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format

        // Combine into formatted time
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} ${ampm}`;

        return formattedTime;
    };


    const handlePageChange = (newPage) => {
        if (newPage === page || newPage < 1 || newPage > totalPages) return;

        setPage(newPage); // This will trigger a re-fetch of data
    };



    const handleSearch = (event) => {
        setPage(1);
        setSearch(event.target.value);
    };

    // const filteredSOSData = sosdata?.filter(sos =>
    //     sos?.user?.name && sos?.user?.name.toLowerCase().includes(searchQuery.toLowerCase())
    // );


    function SosStatusModal(props) {
        const [sosstatus, setSOSstatus] = useState("");
        const [remark, setRemark] = useState(" ");


        useEffect(() => {
            const fetchSOSDetails = async () => {
                try {
                    const response = await axios.get(`${BaseUrl}api/v1/getSosRequestById/${sosId}`, getAuthHeaders())
                    setSOSstatus(response.data.data.status);
                    setRemark(response.data.data.description)
                } catch (error) {
                    console.error('Error fetching SOS details:', error);
                }
            };
            fetchSOSDetails();
        }, [sosId]);

        const handlePut = async (e) => {
            e.preventDefault();
            try {
                await axios.put(
                    `${BaseUrl}api/v1/approvedRejectSosRequestById/${sosId}`, {
                    status: sosstatus,
                    description: remark,
                }, getAuthHeaders(),
                );
                fetchSOSData();
                props.onHide();
                toast.success("SOS Status Updated successfully");
            } catch (error) {
                toast.error("Error to Update SOS Status");
            }
        }


        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className='adminprofileupdate'>
                    <Modal.Title id="contained-modal-title-vcenter">Update SOS Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handlePut}>
                        <Form.Group className="mb-3">
                            <div style={{ display: "flex", gap: "20px" }}>
                                <Form.Check
                                    type="radio"
                                    label="PENDING"
                                    name="status"
                                    checked={sosstatus === "PENDING"}
                                    onChange={() => setSOSstatus("PENDING")}
                                />
                                <Form.Check
                                    type="radio"
                                    label="APPROVED"
                                    name="status"
                                    checked={sosstatus === "APPROVED"}
                                    onChange={() => setSOSstatus("APPROVED")}
                                />
                                <Form.Check
                                    type="radio"
                                    label="REJECT"
                                    name="status"
                                    checked={sosstatus === "REJECT"}
                                    onChange={() => setSOSstatus("REJECT")}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Remark</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                            />
                        </Form.Group>
                        <Modal.Footer>
                            <Button className='sos6' type="submit">Update</Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }

    function SosModal(props) {


        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className='adminprofileupdate'>
                    <Modal.Title id="contained-modal-title-vcenter">SOS Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                    </div>
                </Modal.Body>
            </Modal>
        );
    }





    const handleStatus = (event) => {
        setStatus(event.target.value);
    };
    const handleFromDate = (event) => setFromDate(event.target.value);
    const handleToDate = (event) => setToDate(event.target.value);

    return (
        <>
            <SosStatusModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <SosModal
                show={modalShow1}
                onHide={() => setModalShow1(false)}
            />
            <div className='rider'>
                <div className='rider1'>
                    <div className='rider2'>
                        <div className='rider3'>
                            <h6>SOS Update</h6>
                        </div>

                        <div className='rider4'>
                            <div className='adminearning'>
                                <select
                                    value={status}
                                    onChange={handleStatus}
                                    aria-label="Filter SOS by status"
                                >
                                    <option value="">All Status</option>
                                    <option value="APPROVED">APPROVED</option>
                                    <option value="PENDING">PENDING</option>
                                    <option value="REJECT">REJECT</option>
                                </select>
                            </div>
                            <div className="date-filters">
                                <label>
                                    From:
                                    <input type="date" value={fromDate} onChange={handleFromDate} />
                                </label>
                                <label>
                                    To:
                                    <input type="date" value={toDate} onChange={handleToDate} />
                                </label>
                            </div>
                            <div className='rider5'>
                                <div className='rider6'>
                                    <IoSearch />
                                </div>
                                <input type="search" name="" id="" placeholder='Search name'
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='rider7'>
                        <table>
                            <thead>
                                <tr>
                                    <th>User Image</th>
                                    <th>User Name</th>
                                    <th>Sos Id</th>
                                    <th>Booking Id</th>
                                    <th>Location</th>
                                    <th>Reason for Request</th>
                                    <th>Read</th>
                                    <th>Date & Time</th>
                                    <th>status</th>
                                    <th>Action Buttons</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="10" style={{ color: "#b20202", fontWeight: "600", fontSize: "18px" }}>Loading sos...</td>
                                    </tr>
                                ) : sosdata?.length === 0 ? ( // Check if filtered data is empty
                                    <tr>
                                        <td colSpan="10" style={{ color: "#C3052C", fontWeight: "600", fontSize: "18px" }}>No sos found.</td>
                                    </tr>
                                ) : (
                                    sosdata?.map(SOS => (
                                        <tr key={SOS?.id}>
                                            <td>
                                                <img src={SOS?.user?.profilePicture || img2} alt="No image" style={{ width: '60px', height: "60px", borderRadius: "100%" }} />
                                            </td>
                                            <td>
                                                {SOS?.user?.name?.length > 15 ? `${SOS?.user?.name?.substring(0, 15)}....` : SOS?.user?.name}
                                            </td>
                                            <td>{(SOS?.id)}</td>
                                            <td>{(SOS?.bookingId?.bookingId)}</td>
                                            <td>{(SOS?.locationInWord)}</td>
                                            <td>{(SOS?.reason)}</td>
                                            <td>{(SOS?.isRead ? "Yes" : "NO")}</td>
                                            <td>
                                                {formatDate(SOS?.createdAt)}<br />
                                                {formatTime(SOS?.createdAt)}
                                            </td>
                                            <td style={{
                                                color: SOS.status === 'REJECT' ? '#F52D56' :
                                                    SOS.status === 'PENDING' ? '#FBAC2C' :
                                                        SOS.status === 'APPROVED' ? '#609527' : 'black',
                                                fontWeight: '600'
                                            }}>
                                                {SOS.status}
                                            </td>
                                            <td className='rider9'>
                                                <div className='rider10'>
                                                    <Link
                                                        to={`/soslocation/${SOS._id}`}
                                                        className='sidebar-link'
                                                        state={{ page }}
                                                    >
                                                        <IoLocationSharp color='#000000' size={22} />
                                                        <p style={{ fontSize: '10px' }}>Track Live Location</p>
                                                    </Link>
                                                </div>
                                                <div className='rider10'
                                                    onClick={() => {
                                                        setSOSId(SOS?._id);
                                                        setModalShow(true);
                                                    }}
                                                >
                                                    <MdEdit color='#000000' size={20} />
                                                    <p>Edit</p>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>



                <div className='rider_details555'>
                    <CustomPagination
                        page={page}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </div>
        </>
    )
}

export default HOC(SOSUpdate);
