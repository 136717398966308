import React, { useMemo, useState, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

const containerStyle = {
    width: '100%',
    height: '60vh',
};

const MapTwogeo = ({ locations }) => {
    const loaderOptions = useMemo(() => ({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, 
    }), []);

    const { isLoaded } = useJsApiLoader(loaderOptions);
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [hoveredLocation, setHoveredLocation] = useState(null);

    const calculateCenter = useMemo(() => {
        if (locations.length === 0) return { lat: 0, lng: 0 };
        const latSum = locations.reduce((sum, loc) => sum + loc.latitude, 0);
        const lngSum = locations.reduce((sum, loc) => sum + loc.longitude, 0);
        return {
            lat: latSum / locations.length,
            lng: lngSum / locations.length,
        };
    }, [locations]);

    const handleMarkerClick = (driverId) => {
        navigate(`/driver_details/${driverId}`); // Redirect to driver profile
    };

    const handleMouseOver = (e, location) => {
        setShow(true);
        setTarget(e.domEvent.target); // Set the DOM element target
        setHoveredLocation(location); // Store the hovered location
    };


    const handleMouseOut = () => {
        setShow(false);
        setTarget(null);
        setHoveredLocation(null); // Clear the hovered location
    };

    return isLoaded ? (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={calculateCenter}
                zoom={12}
            >
                {locations.map((location, index) => (
                    <Marker
                        key={index}
                        position={{ lat: location.latitude, lng: location.longitude }}
                        icon={{ url: 'https://maps.gstatic.com/mapfiles/ms2/micons/motorcycling.png' }}
                        onClick={() => handleMarkerClick(location.driverId)}
                        onMouseOver={(e) => handleMouseOver(e, location)}
                        onMouseOut={handleMouseOut}
                    />
                ))}
            </GoogleMap>
            <Overlay target={target} show={show} placement="right">
                {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                        {hoveredLocation?.driverName || "No Name Available"}
                    </Tooltip>
                )}
            </Overlay>

        </>
    ) : (
        <p>Loading map...</p>
    );
};

export default MapTwogeo;
