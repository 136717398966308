import React, { useCallback, useEffect, useState } from 'react'
import './Navbar.css'

import { BaseUrl, getAuthHeaders } from '../../Components/BaseUrl/BaseUrl';


import { AiOutlineSetting } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';

import img2 from '../../Images/user.webp'
import axios from 'axios';


const Navbar = () => {

    const navigate = useNavigate()

    const handleLogout = () => {
        localStorage.clear();
    };

    const [usemessagecount, setUserMessageCount] = useState(0);
    const [drivermessageCount, setDriverMessageCount] = useState(0);
    const [adminprofile, setAdminProfile] = useState(null)
    const [adminname, setAdminName] = useState(null)
    const [sosCount, setSOSCount] = useState(0);

    const fetchSoscount = useCallback(() => {
        axios.get(`${BaseUrl}api/v1/getSosRequestCount`, getAuthHeaders())
            .then(response => {
                setSOSCount(response.data.data);
                setUserMessageCount(response.data.user);
                setDriverMessageCount(response.data.driver);
            })
            .catch(error => {
                console.error('Error fetching count data:', error);
            })
    }, []);

    useEffect(() => {
        fetchSoscount();

        const interval = setInterval(() => {
            fetchSoscount();
        }, 30000);

        return () => clearInterval(interval);
    }, [usemessagecount, drivermessageCount, sosCount]);


    const handlePutUserRequest = async (e) => {
        try {
            const response = await axios.post(`${BaseUrl}api/v1/ChatCount/zeroChatCount`, getAuthHeaders());
        } catch (error) {
            console.log('Error to updating User Details:', error)
        }
    }
    const handlePutDriverRequest = async (e) => {
        try {
            const response = await axios.post(`${BaseUrl}api/v1/ChatCount/zeroChatCountForDriver`, getAuthHeaders())
        } catch (error) {
            console.log('Error to updating User Details:', error)
        }
    }


    const handleuserzero = () => {
        navigate('/livechart')
        handlePutUserRequest()
    }

    const handleDriverzero = () => {
        navigate('/liveChartWithDriver')
        handlePutDriverRequest()
    }



    const fetchAdminData = async () => {
        try {
            const response = await axios.get(`${BaseUrl}api/v1/admin/me`, getAuthHeaders());
            const data = response?.data?.data;
            setAdminProfile(data?.profilePicture);
            setAdminName(data?.name);
            localStorage.setItem('adminData', JSON.stringify(data));
        } catch (error) {
            console.error('Error fetching admin data:', error);
        }
    };


    useEffect(() => {
        fetchAdminData()
    }, [])



    const cachedAdminData = localStorage.getItem('adminData');
    const adminData = JSON.parse(cachedAdminData);
    const role = localStorage.getItem('role');

    let permissionsArray = [];
    if (adminData?.permissions) {
        permissionsArray = adminData.permissions;
    } else {
        console.log('Permissions array not found in adminData.');
    }

    return (
        <>
            <div className='navbar'>
                <div className='navbar10'>
                    <div className='navbar1' onClick={() => navigate('/adminprofile')}>
                        <img
                            src={adminprofile  || img2}
                            alt={adminname}
                        />
                        <div className='navbar2'>
                            <h6>Mr {adminname}</h6>
                            <span>{role}</span>
                        </div>
                        <div className='navbar11'>
                            <button onClick={handleLogout}>Logout</button>
                        </div>
                    </div>

                    <div className='navbar3'>
                        {/* <div className='navbar4'>
                            <div className='navbar5'>
                                <BiSearch className="search-icon" />
                            </div>
                            <input type="text" placeholder="Search in admin Panel" className="search-input" />
                        </div> */}

                        {role === 'superAdmin' ? (
                            <>
                                <div className='navbar11'>
                                    <button onClick={() => navigate('/sos')}>SOS Request</button>
                                    <div className='navbar20'>
                                        <p>{sosCount}</p>
                                    </div>
                                </div>
                                <div className='navbar11'>
                                    <button onClick={handleuserzero}>User Message</button>
                                    <div className='navbar20'>
                                        <p>{usemessagecount}</p>
                                    </div>
                                </div>
                                <div className='navbar11'>
                                    <button onClick={handleDriverzero}>Driver Message</button>
                                    <div className='navbar20'>
                                        <p>{drivermessageCount}</p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            permissionsArray.map((permission, index) => {
                                switch (permission.name) {
                                    case "SOS Updates":
                                        return (
                                            <div className='navbar11' key={`sos-${index}`}>
                                                <button onClick={() => navigate('/sos')}>SOS Request</button>
                                                <div className='navbar20'>
                                                    <p>{sosCount}</p>
                                                </div>
                                            </div>
                                        );

                                    case "Live Chat":
                                        return (
                                            <>
                                                <div className='navbar11' key={`user-${index}`}>
                                                    <button onClick={handleuserzero}>User Message</button>
                                                    <div className='navbar20'>
                                                        <p>{usemessagecount}</p>
                                                    </div>
                                                </div>
                                                <div className='navbar11' key={`driver-${index}`}>
                                                    <button onClick={handleDriverzero}>Driver Message</button>
                                                    <div className='navbar20'>
                                                        <p>{drivermessageCount}</p>
                                                    </div>
                                                </div>
                                            </>
                                        );

                                    default:
                                        return null; // Skip unknown permissions
                                }
                            })
                        )}


                        < div className='navbar6'>
                            {role === 'superAdmin' ? (
                                <div className='navbar7' onClick={() => navigate('/setting')}>
                                    <div className='navbar8'>
                                        <AiOutlineSetting />
                                    </div>
                                    <p>Settings</p>
                                </div>
                            ) : (
                                ""
                            )
                            }
                        </div>
                    </div>
                </div>

            </div >
        </>
    )
}

export default Navbar