import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import HOC from '../../Components/HOC/HOC'
import { useNavigate } from 'react-router-dom';

import { RiDeleteBinLine } from "react-icons/ri";
import { BaseUrl, getAuthHeaders } from '../../Components/BaseUrl/BaseUrl';






const Coupan = () => {
    const cachedAdminData = localStorage.getItem('adminData');
    const adminData = JSON.parse(cachedAdminData);
    const role = localStorage.getItem('role');

    let permissionsArray = [];

    if (adminData && adminData.permissions) {
        permissionsArray = adminData.permissions;
    } else {
        console.log('Permissions array not found in adminData.');
    }
    const navigate = useNavigate();
    const [couponData, setCouponData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchCoupanData();
    }, []);




    const fetchCoupanData = () => {
        axios.get(`${BaseUrl}api/v1/Coupon/listCoupon`, getAuthHeaders())
            .then(response => {
                setCouponData(response.data.service);
            })
            .catch(error => {
                console.error('Error fetching coupon data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const deleteCoupan = (coupanId) => {
        axios.delete(`${BaseUrl}api/v1/Coupon/${coupanId}`, getAuthHeaders())
            .then(response => {
                // console.log('Rider deleted successfully');
                toast.success("coupon deleted successfully");
                fetchCoupanData();
            })
            .catch(error => {
                console.error('Error deleting coupon:', error);
                toast.error("Error deleting coupon");
            });
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);

        // Ensure day and month are two digits
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        const formattedDate = `${day}-${month}-${year}`;

        return formattedDate;
    };


    const subtractOneDay = (dateString) => {
        const [day, month, year] = dateString.split("-").map(Number);

        const date = new Date(year, month - 1, day);

        date.setDate(date.getDate() - 1);

        const newDay = String(date.getDate()).padStart(2, "0");
        const newMonth = String(date.getMonth() + 1).padStart(2, "0");
        const newYear = date.getFullYear();

        return `${newDay}-${newMonth}-${newYear}`;
    };


    return (
        <>
            <div className='rider'>
                <div className='rider1'>
                    <div className='rider2'>
                        <div className='rider3'>
                            <h6>coupon</h6>
                        </div>
                        {role === 'superAdmin' ? (
                            <div className='rider4'>
                                <button onClick={() => navigate('/addcoupon')}>Add coupon</button>
                            </div>
                        ) : (
                            <>
                                {
                                    permissionsArray.some(permission => permission.name === 'Coupon' && permission.add) && (
                                        <div className='rider4'>
                                            <button onClick={() => navigate('/addcoupon')}>Add coupon</button>
                                        </div>
                                    )
                                }
                            </>
                        )}
                    </div>
                    <div className='rider7'>
                        <table>
                            <thead>
                                <tr>
                                    <th>SR. No.</th>
                                    <th>Coupon Code</th>
                                    <th>User Name</th>
                                    <th>Category</th>
                                    <th>Discount</th>
                                    <th>Activation Date</th>
                                    <th>Expiration Date</th>
                                    <th>Used Status</th>
                                    <th>Expire Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="10" style={{ color: "#b20202", fontWeight: "600", fontSize: "18px" }}>Loading coupans...</td>
                                    </tr>
                                ) :
                                    couponData?.length === 0 ? (
                                        <tr>
                                            <td colSpan="10" style={{ color: "#b20202", fontWeight: "600", fontSize: "18px" }}>Coupan not found</td>
                                        </tr>
                                    ) : (
                                        couponData?.map((coupon, index) => (
                                            <tr key={coupon.id}>
                                                <td>{index + 1}</td>
                                                <td>{coupon.code}</td>
                                                <td>
                                                    {coupon?.user?.length === 0 ? (
                                                        "All User"
                                                    ) : (
                                                        <ul>
                                                            {coupon?.user?.map((username, index) => (
                                                                <li key={index}>{username?.name}</li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </td>

                                                <td>
                                                    {coupon.vehicleId && <p>Vehicle Name: {coupon.vehicleId.name}</p>}
                                                    {coupon.vehicleAmbulanceId && <p>Ambulance Name: {coupon.vehicleAmbulanceId.name}</p>}
                                                    {coupon.superCar && <p>Super Car Name: {coupon.superCar.name}</p>}
                                                </td>
                                                <td className='vehicle12'>{coupon.discountPer}</td>
                                                <td>{formatDate(coupon.activationDate)}</td>
                                                <td>{subtractOneDay(formatDate(coupon.expirationDate))}</td>
                                                <td className='vehicle3'>
                                                    {coupon.usedBy.length === 0 ? (
                                                        <div className='promo'><p>Not Used</p></div>
                                                    ) : (
                                                        <div className='vehicle'><p>Used</p></div>
                                                    )}
                                                </td>
                                                <td className='vehicle3'>
                                                    {coupon.isExpired ? (
                                                        <div className='promo'><p>Expired</p></div>
                                                    ) : (
                                                        <div className='vehicle'><p>Not Expired</p></div>
                                                    )}
                                                </td>
                                                <td>
                                                    <div className='rider9'>
                                                        {role === 'superAdmin' ? (
                                                            <div className='rider10' onClick={() => deleteCoupan(coupon._id)}>
                                                                <RiDeleteBinLine color='#667085' size={20} />
                                                                <p>Delete</p>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {
                                                                    permissionsArray.some(permission => permission.name === 'Coupon' && permission.delete) && (
                                                                        <div className='rider10' onClick={() => deleteCoupan(coupon._id)}>
                                                                            <RiDeleteBinLine color='#667085' size={20} />
                                                                            <p>Delete</p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HOC(Coupan)