// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.adminearning{
    
}


.adminearning select{
    width: auto;
    height: 35.2px;
    outline: none;
    border-radius: 10.41px;
    padding-left: 10px;
}


`, "",{"version":3,"sources":["webpack://./src/Pages/AdminEarning/AdminEarning.css"],"names":[],"mappings":";AACA;;AAEA;;;AAGA;IACI,WAAW;IACX,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB","sourcesContent":["\n.adminearning{\n    \n}\n\n\n.adminearning select{\n    width: auto;\n    height: 35.2px;\n    outline: none;\n    border-radius: 10.41px;\n    padding-left: 10px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
