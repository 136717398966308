import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as CryptoJS from 'crypto-js';

const PhonePePayment = ({ username, mobilenumber, upiId, amount }) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Configuration
    const merchantId = 'M226S67335HHD'; // Replace with actual merchant ID
    const saltKey = '4025efbc-c7b0-48be-93ed-8c33236db37b'; // Replace with actual salt key
    const saltIndex = 1;
    const environment = 'SANDBOX';  // Use "PRODUCTION" for live transactions

    // Function to base64 encode a string
    const base64Encode = (str) => {
        const encoder = new TextEncoder();
        const bytes = encoder.encode(str);
        return btoa(String.fromCharCode(...bytes));  // Convert to base64
    };

    // Function to calculate the X-VERIFY checksum
    const calculateXVerify = (base64Payload) => {
        const endpoint = '/pg/v1/pay';
        const dataToHash = base64Payload + endpoint + saltKey;

        // Hash the data with SHA256 and encode as Base64
        const hash = CryptoJS.SHA256(dataToHash).toString(CryptoJS.enc.Base64);
        return hash + '###' + saltIndex;
    };

    const handlePayment = async () => {
        setLoading(true);

        const transactionId = `txn_${Date.now()}`;
        const requestData = {
            merchantId,
            merchantTransactionId: transactionId,
            amount: parseFloat(amount) * 100, // Convert to paisa
            merchantUserId: mobilenumber,
            mobileNumber: mobilenumber,
            redirectUrl: 'https://your-redirect-url.com',  // Replace with your redirect URL
            redirectMode: 'REDIRECT',  // or "POST"
            callbackUrl: 'https://your-callback-url.com',  // Replace with your callback URL
            paymentInstrument: {
                type: 'UPI',
                target: upiId,
            },
        };

        // Step 1: Base64 encode the request data
        const base64Payload = base64Encode(JSON.stringify(requestData));

        // Step 2: Calculate the X-VERIFY checksum
        const xVerify = calculateXVerify(base64Payload);

        // Step 3: Construct the request
        // const url = environment === 'SANDBOX'
        //     ? 'https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay'
        //     : 'https://api.phonepe.com/apis/hermes/pg/v1/pay';

        // Set up the options for the axios request
        const options = {
            method: 'post',
            url: 'https://api.phonepe.com/apis/hermes/pg/v1/pay',  // Confirm the URL if necessary
            headers: {
                'Content-Type': 'application/json',  // Ensure this is correct
                'accept': 'text/plain',         // Make sure the API expects JSON
                'X-VERIFY': xVerify,                  // Ensure checksum is correctly generated
            },
            data: {
                request: base64Payload,              // Make sure the payload structure is correct
            }
        };
        
        axios.request(options)
            .then(function (response) {
                console.log(response.data);
            })
            .catch(function (error) {
                console.error('Error initiating payment:', error.response ? error.response.data : error.message);
                toast.error('Error initiating payment');
            });
        

        // Axios request
        try {
            const response = await axios.request(options);
            console.log(response.data);

            if (response.data.success) {
                toast.success('Payment Initiated!');
                // Redirect to the provided URL from the response
                window.location.href = response.data.data.instrumentResponse.redirectUrl;
            } else {
                toast.error('Payment Failed!');
            }
        } catch (error) {
            console.error('Error initiating payment:', error.response ? error.response.data : error.message);
            toast.error('Error initiating payment');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="phonepe-payment">
            <h3>Complete Payment with PhonePe</h3>
            <p><strong>User:</strong> {username}</p>
            <p><strong>Mobile Number:</strong> {mobilenumber}</p>
            <p><strong>UPI ID:</strong> {upiId}</p>
            <p><strong>Amount:</strong> ₹{amount}</p>

            <button onClick={handlePayment} disabled={loading}>
                {loading ? 'Processing...' : 'Pay with PhonePe'}
            </button>
        </div>
    );
};

export default PhonePePayment;
