// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rideronline {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.rideronline label {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
    line-height: 48px;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/All Drivers/Drivers.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,kCAAkC;IAClC,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,iBAAiB;IACjB,SAAS;AACb","sourcesContent":[".rideronline {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 0.5rem;\r\n}\r\n\r\n.rideronline label {\r\n    font-family: \"Poppins\", sans-serif;\r\n    font-weight: 600;\r\n    font-size: 15px;\r\n    color: #ffffff;\r\n    line-height: 48px;\r\n    margin: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
